<template>
  <v-container>
    <div class="d-flex">
      <div class="display-1 mb-2 mr-auto"> Entity </div>
        <v-tooltip
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="success"
              @click="showDialog = true"
              v-bind="attrs"
              v-on="on"
              fab
              dark
              x-small
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <span>Add New Entities</span>
        </v-tooltip>
      <new-fiware-entity v-model="showDialog" @fetch="fetchEntity()"></new-fiware-entity>
    <!-- {{ $route.query.page }} -->
    </div>
    <template
      v-if="isFetch"
      class="text-center"
    >
      <v-pagination
        circle
        v-model="page"
        :length="paginationLength"
        :total-visible="7"
        @input="$router.push({ query: { page: page } }), fetchEntity()"
      ></v-pagination>
    </template>
    <template v-if="!isFetch">
      <loading-list />
    </template>
    <list-item :items="entityItems" title="id" icon="mdi-desktop-tower" hasDelete link @delete="deleteItem($event)"></list-item>
    <!-- <v-jsoneditor v-model="tt" :options="options" :plus="false" height="400px"></v-jsoneditor> -->
  </v-container>
</template>

<script>
import api from '@/services/api'

export default {
  name: 'FiwareEntity',
  components: {
    NewFiwareEntity: () => import('@/components/fiware/NewFiwareEntity'),
    ListItem: () => import('@/components/ListItem'),
    LoadingList: () => import('@/components/LoadingList')
  },
  data () {
    return {
      isFetch: false,
      model: 1,
      page: 1,
      paginationLength: 1,
      entitiesLimit: 20,
      entitiesOffset: 0,
      entityItems: [],
      showDialog: false,
      showConfirmDelete: false,
      tt: {
        name: null,
        surname: null,
        customer: {
          address: null
        }
      },
      options: {
        mode: 'code',
        templates: [
          {
            text: 'Person',
            title: 'Insert a Person Node',
            className: 'jsoneditor-type-object',
            field: 'PersonTemplate',
            value: {
              'firstName': 'John',
              'lastName': 'Do',
              'age': 28
            }
          },
          {
            text: 'Address',
            title: 'Insert a Address Node',
            field: 'AddressTemplate',
            value: {
              'street': '',
              'city': '',
              'state': '',
              'ZIP code': ''
            }
          }
        ],
        onValidate: function (json) {
          var errors = []
          for (const iterator in json) {
            if (iterator === 'id') {
              errors.push({
                path: [iterator],
                message: `Required property "${iterator}" missing.`
              })
            }
          }
          if (json && json.customer && !json.customer.address) {
            errors.push({
              path: ['customer'],
              message: 'Required property "address" missing.'
            })
          }
          return errors
        },
        onChangeText: function (json) {
          // console.log(json)
        }
      }
    }
  },
  computed: {
    cssVars: function () {
      return {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        height: '70px'
        // height: `${this.$vuetify.breakpoint.height / 2}px`
      }
    }
  },
  methods: {
    fetchEntity: function () {
      let p = parseInt(this.$route.query.page) > 0 ? parseInt(this.$route.query.page) : 1
      api.get('/f/v2/entities?limit=1&options=count', this.$store.state.fiwareService, null, this.$store.state.tokenRPT).then((response) => {
        let fwTotalCount = isNaN(parseInt(response.headers.get('fiware-total-count'))) ? 0 : parseInt(response.headers.get('fiware-total-count'))
        this.paginationLength = fwTotalCount === 0 ? 1 : Math.ceil(fwTotalCount / this.entitiesLimit)
        if (p <= this.paginationLength) {
          this.page = p
        } else {
          this.page = this.paginationLength
        }
        if (response.status === 200) {
          api.get(`/f/v2/entities?limit=${this.entitiesLimit}&offset=${this.entitiesLimit * (this.page - 1)}&options=count`, 'demo', null, this.$store.state.tokenRPT).then((response) => {
            response.json().then((json) => {
              this.entityItems = json
            })
          })
        }
        this.isFetch = true
      })
    },
    deleteItem: function (itemName) {
      api.delete(`/f/v2/entities/${itemName}`, this.$store.state.firewareServices, '/test').then((response) => {
        if (response.status === 204) {
          const rm = this.entityItems.filter(entitie => entitie.id !== itemName)
          this.entityItems = rm
        }
      })
    }
  },
  watch: {
    '$store.state.fiwareService': function () {
      this.fetchEntity()
    }
  },
  created () {
    this.fetchEntity()
    // console.log(parseInt(this.$route.query.page))
    // this.fetchKc()
  }
}
</script>
